﻿header {
    .headerlogo {
        .svg.svg-wrap {
            height: 2.1875rem;
            padding-top: initial;
            width: 11rem;
        }
    }

    .languagemenuwrap {

        a, a:link, {
            color: $body-color;
            text-decoration: none;
            font-size: 0.9rem;
        }

        a:hover {
            text-decoration: none;
            border-bottom: 1px solid $body-color;
        }

        .dropdown-menu {
            min-width: 4rem;

            .dropdown-item {
                a, a:link, {
                    display: block;
                    text-decoration: none;
                    border-bottom: none;
                }

                a:hover {
                    text-decoration: none;
                    border-bottom: none;
                }
            }
        }
    }

    .loginbackgroundshape {
        position: absolute;
        z-index: -1;
        width: 100%;
        left: 0%;
        bottom: 0rem;
        display: block;
        min-height: 100%;
        display: none;

        @include media-breakpoint-up(lg) {
            position: absolute;
            z-index: -1;
            width: 100%;
            left: 0%;
            top: -4rem;
            display: none;
        }
    }

    .productlogowrap {

        .productlogo {
            width: 100%;
            height: 4rem;

            @include media-breakpoint-down(md) {
                height: 2rem;
            }

            .svg.svg-wrap {
                height: 100%;
                padding-top: initial;
                text-align: left;
            }
        }
    }
}
