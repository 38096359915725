﻿footer {
    .footerstardekklogo {
        .svg.svg-wrap {
            height: 1.8755rem;
            padding-top: initial;

            &.svg-wrap-logostardekk {
                width: 10rem;
            }
        }
    }

    .contactinfo {
        & > a {
            color: #4f6e8f;
            font-size: 1rem;
            line-height: 1.6;
            letter-spacing: -0.02rem;
            text-decoration: none;
        }
    }

    .socials {
        & > a {
            color: #4f6e8f;
            font-size: 1rem;
            line-height: 1.6;
            letter-spacing: -0.02rem;
            text-decoration: none;
            width: 2rem;
            margin-right: 0.5rem;
        }
    }

    .footertitle {
        color: #283e6c;
        font-weight: 700;

        & > a {
            color: #283e6c;
            font-size: 0.7rem;
            display: block;
            line-height: 1.5rem;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;

        li {
            margin-bottom: 0.5rem;

            a {
                text-decoration: none;
                color: #4f6e8f !important;
                font-size: 1rem;

                span {
                    display: inline-block;
                    margin-left: 0.3rem;
                    padding: 0.2rem 8px;
                    border-radius: 0.7rem;
                    background-color: #fff6e8;
                    color: #ff6200;
                    font-size: 0.8rem;
                    letter-spacing: 0.05rem;
                    text-transform: uppercase;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            .collapse:not(.show) {
                display: block !important;
                height: auto !important;
                visibility: visible !important;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .collapse:not(.show) {
            display: block !important;
            height: auto !important;
            visibility: visible !important;
        }
    }

    .condition {
        color: #99acc2;
        font-size: 0.8rem;
        text-decoration: none;

        @include media-breakpoint-down(lg) {
            text-align: left !important;
            margin-bottom: 0.5rem;
        }
    }

    .conditionnav {

        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                margin-bottom: 0;
                display: flex;
                flex-wrap: wrap;
                flex: 50%;

                @include media-breakpoint-up(sm) {
                    border-left: solid 1px $grey;
                    margin-left: 1rem;
                    padding-left: 1rem;
                    display: inline-block;

                    &:first-child {
                        border-left: none;

                        @include media-breakpoint-down(lg) {
                            padding-left: 0rem;
                            margin-left: 0rem;
                        }
                    }
                }

                a {
                    color: #99acc2 !important;
                    font-size: 0.8rem;
                    text-decoration: none;
                }
            }

            @include media-breakpoint-up(sm) {
                flex: none;
                display: block;
            }
        }

        @include media-breakpoint-down(lg) {
            text-align: left !important;
        }
    }
}
.footershapes {
    position: absolute;
    z-index: -1;
    left: 0;
    width: 100%;
    bottom: 0;

    & > .svg-wrap-loginbottomleftshape {
        width: 220px;
        position: absolute;
        left: 0;
        bottom: 0;
        padding-top: 0;
        height: 64px;


        @include media-breakpoint-down(lg) {
            width: 150px;
            height: 44px;
        }
    }

    & > .svg-wrap-loginbottomrightshape {
        width: 150px;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 67px;
        padding-top: 0;

        @include media-breakpoint-down(lg) {
            width: 95px;
            height: 44px;
        }
    }
}