﻿.svg-wrap {
    display: block;
    width: 100%;
    padding-top: 100%;
    position: relative;

    & > svg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &.svg-wrap-nodata {
        padding-top: 106%;
    }

    &.svg-wrap-logoonlystardekk {
        padding-top: 122%;
    }

    &.svg-wrap-logostardekk {
        padding-top: 18.5%;
    }

    &.svg-wrap-logocubilis {
        padding-top: 27%;
    }

    &.svg-wrap-logoonlycubilis {
        padding-top: 111%;
    }

    &.svg-wrap-logobookingplanner {
        padding-top: 14%;
    }

    &.svg-wrap-logoonlybookingplanner {
        padding-top: 137%;
    }

    &.svg-wrap-logolittlerestaurant {
        padding-top: 11%;
    }

    &.svg-wrap-logoonlylittlerestaurant {
        padding-top: 383%;
    }

    &.svg-wrap-logoportalbooker {
        padding-top: 11%;
    }

    &.svg-wrap-logoonlyportalbooker {
        padding-top: 122%;
    }

    &.svg-wrap-logowebsitemanager {
        padding-top: 11%;
    }

    &.svg-wrap-logoonlywebsitemanager {
        padding-top: 66%;
    }

    &.svg-wrap-logoyounight {
        padding-top: 27%;
    }

    &.svg-wrap-logoonlyyounight {
        padding-top: 129%;
    }

    &.svg-wrap-logoemaillinglist {
        padding-top: 15%;
    }

    &.svg-wrap-logoonlyemaillinglist {
        padding-top: 100%;
    }

    &.svg-wrap-loginbackgroundshape {
        padding-top: 37.5%;
    }

    &.svg-wrap-loginbottomrightshape {
        padding-top: 45%;
    }

    &.svg-wrap-loginbottomleftshape {
        padding-top: 28.5%;
    }

    &.svg-wrap-facebook {
        padding-top: 50%;
    }

    &.svg-wrap-twitter {
        padding-top: 50%;
    }

    &.svg-wrap-linkedin {
        padding-top: 50%;
    }

    &.svg-wrap-instagram {
        padding-top: 50%;
    }
}
