﻿@import "utilities/fonts.scss";

$font-family-base: "Gt walsheim", sans-serif;
$body-bg: #F9FAFB;
$body-color: #333B45;
$blue: #125FE3;
$secondary:  #ffffff;
$grey: #BDC4CE;

@import "../../../node_modules/@fortawesome/fontawesome-pro/css/fontawesome.css";
@import "../../../node_modules/@fortawesome/fontawesome-pro/css/solid.css";

@import "utilities/bs-fontextention.scss";

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

a {
    color: $blue;
}

@import "utilities/functions.scss";
@import "elements/svg.scss";
@import "elements/h-tags.scss";
@import "elements/container.scss";
@import "elements/btn.scss";
@import "components/login/form.scss";
@import "components/card.scss";
@import "layout/login/header.scss";
@import "layout/footer.scss";