﻿.card {
    border-radius: 8px;
    &.shadow-lg {
        box-shadow: 0px 1px 1.2px 0px rgba(0, 0, 0, 0.04), 0px 1.5px 1.8px -1.1px rgba(0, 0, 0, 0.06), 0px 3.2px 3.7px -1.2px rgba(0, 0, 0, 0.08) !important;

        @include media-breakpoint-down(md) {
            box-shadow: none !important;
        }
    }

    &.productcard, &.walletcard {
        .card-header {
            #{headings(1,6)} {
                margin-bottom: 0;

                .producttypelogo {
                    width: 1.5rem;

                    &.restobooker {
                        width: 0.35rem !important;
                    }
                }
            }
        }

        .card-body {
            .btn {
                font-size: 0.8rem;
                padding-left: 1.25rem;
                padding-right: 1.25rem;
            }
        }
    }
}
