﻿@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@font-face {
    font-family: 'Gt walsheim';
    src: url('../fonts/GT-Walsheim-Bold.otf') format('opentype'), url('../fonts/GT-Walsheim-Bold.woff2') format('woff2'), url('../fonts/GT-Walsheim-Bold.woff') format('woff'), url('../fonts/GT-Walsheim-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gt walsheim';
    src: url('../fonts/GT-Walsheim-Regular.otf') format('opentype'),url('../fonts/GT-Walsheim-Regular.woff2') format('woff2'), url('../fonts/GT-Walsheim-Regular.woff') format('woff'), url('../fonts/GT-Walsheim-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}