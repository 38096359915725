﻿/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: #333b45;
}

form {
    .form-label {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.3rem;
    }

    .form-control {
        border: 1px solid $grey;
        border-radius: 2px;

        &.form-control-lg {
            font-style: normal;
            font-weight: normal;
            font-size: 1rem;
            line-height: 1.3rem;
            color: #333b45;
            padding: 1rem;
            height: 2.5rem;

            @include media-breakpoint-up(md) {
                height: 3.5rem;
                ;
            }

            &:placeholder {
                color: #8C929F;
            }
        }
    }

    .form-check-label {
        font-size: 0.9rem;
    }
}
